import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component/lib/util';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import template from './GestionSeuilMinimumOperation.Template.vue';
import { DateHelper } from '@/shared/helpers';

import { DataTableHeader, PagingAndSorting, Result } from '@/shared/models';
import { SeuilMinimumOperation } from '@/models';
import { AxiosResponse } from 'axios';
import { ApiService } from '@/services/base/ApiService';

@Component({
    ...template,
    name: 'GestionSeuilMinimumOperation',
})
export default class GestionSeuilMinimumOperation extends mixins(GrilleMixin) {

    // Seuil actif.
    public texteSeuilActif: string = 'Chargement en cours...';

    // Entêtes de colonnes.
    public headers: DataTableHeader[] = [
        { text: 'Date d\'activation' },
        { text: 'Valeur (MWhc)' }
    ];

    // Données de la grille.
    public seuils: SeuilMinimumOperation[] = [];
    public totalSeuils: number = 0;

    // Indique un chargement en cours.
    public loading: boolean = false;

    /**
     * Montage de la vue.
     * */
    public mounted() {

        // Récupération du seuil actif.
        this.getSeuilActif();

        // Tri descendant sur la date par défaut.
        this.pagination.sortBy = ['DateActivation'];
        this.pagination.descending = true;
    }

    /**
     * Méthode de récupération des données historique de la grille.
     * */
    public getData(): void {
        const pagingAndSorting = PagingAndSorting.buildFromPaginationObject(this.pagination);
        this.getAllSeuils(pagingAndSorting);
    }

    /**
     * Méthode d'appel à l'Api.
     * @param criteria Le critère de recherche contenant la pagination et le tri.
     */
    public getAllSeuils(pagingAndSorting: PagingAndSorting): Promise<AxiosResponse<Result<SeuilMinimumOperation[]>>> {
        this.loading = true;
        const seuilsApi = new ApiService<SeuilMinimumOperation>('seuilMinimumOperation/obtenirHistoriqueSeuilsMinimumOperation');
        return new Promise<AxiosResponse<Result<SeuilMinimumOperation[]>>>((resolve, reject) => {
            return seuilsApi.getWhere(pagingAndSorting.queryString).then((response) => {
                this.seuils = response.data.data;
                this.totalSeuils = response.data.totalCount;
                this.$set(this.pagination, 'totalItems', this.totalSeuils);
                resolve(response);
            })
                .catch((error: { response: any; }) => {
                    reject(error.response);
                })
                .finally(() => this.loading = false);
        });
    }

    /**
     * Récupération du seuil actif.
     * */
    public getSeuilActif() {
        const seuilApi = new ApiService<SeuilMinimumOperation>('seuilMinimumOperation/obtenirSeuilActif');
        return new Promise<AxiosResponse<SeuilMinimumOperation>>((resolve, reject) => {
            return seuilApi.getWhereSingle(undefined).then((response) => {
                const seuilActif = response.data;
                this.texteSeuilActif = `<strong>${seuilActif.valeur} MWhc</strong> depuis le <strong>${this.formatDate(seuilActif.dateActivation)}</strong>`;
                if (seuilActif.dateFin) {
                    this.texteSeuilActif += ` jusqu'au <strong>${this.formatDate(seuilActif.dateFin)}</strong>`;
                }
                resolve(response);
            })
                .catch((error: { response: any; }) => {
                    this.texteSeuilActif = 'Erreur lors de la récupération du seuil.'
                    reject(error.response);
                });
        });
    }

    /**
     * Formatte une date pour qu'elle soit au format FR.
     * @param date La date.
     */
    public formatDate(date: string): string {
        return DateHelper.format(date);
    }

}